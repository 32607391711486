/* eslint-disable */
console.log('此项目已在Github上开源：https://github.com/mcmyth/home-page')
export default {
  beian: 'zipeng.org | 2024',
  buttonList: [
    {
      img: '/assets/icon/qq.svg',
      title: 'QQ群',
      link: 'http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=p9KH6ghTOTfXCmgZZZdGLtYp4jWL5ia2&authKey=o5BWNwUvAZC3l03RvO5%2Bb%2FB3VWAsxKpp14hy3pyTsjCXc0gEWt1OVYkKa7u0GtSx&noverify=0&group_code=936113204'
    },
    {
      img: '/assets/icon/bilibili.svg',
      title: 'Bilibili',
      link: 'https://space.bilibili.com/85302498'
    },
    {
      img: '/assets/icon/163music.svg',
      title: '163MUSIC',
      link: 'https://music.163.com/#/artist?id=31796549'
    },
    {
      img: '/assets/icon/dx.ico',
      title: '落雪查分器',
      link: 'https://maimai.lxns.net/'
    }
  ]
}